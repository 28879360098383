import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from 'react-bootstrap';
import Select from "react-select";
import { CallCenter } from "../onsight-plus-ext";
import { CallCenterInstanceName, translate } from "../index";

const CallbackForm = (props) => {
    const priorityOptions = [
        { label: translate("URGENT"), value: "Urgent" },
        { label: translate("NOT_URGENT"), value: "NotUrgent" },
    ];
    
    const [selectedPriority, setSelectedPriority] = useState(priorityOptions[0]);
    const [comments, setComments] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const emailParam = searchParams.get("email");
    const email = (!emailParam || emailParam == "null") ? null : emailParam;

    const handleSubmit = async () => {
        const response = await CallCenter.updateRequest(CallCenterInstanceName, props.requestId, {
            id: props.requestId,
            status: "RequiresCallback",
            requesterEmail: email,
            callback: {
                priority: selectedPriority.value,
                comments
            }
        });

        setSubmitted(true);
        window.close();
    };

    return (
        <div style={{display: props.hidden ? "none" : "inherit", width: "100%"}}>
            {!submitted && <div className="card" style={{ margin: "0.5em", width: "100%" }}>
                <div className="card-header font-weight-bold">{translate("REQUEST_CALLBACK")}</div>
                <div className="form-body">
                    <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
                        <div style={{ flexGrow: "1" }}>
                            <div style={{ height: "100%", width: "100%" }}>
                                <Select
                                    style={{width: "25%"}}
                                    value={selectedPriority}
                                    onChange={newValue => setSelectedPriority(newValue)}
                                    options={priorityOptions} />
                                <p />
                                <textarea
                                    className="form-control me-2"
                                    rows="3"
                                    value={comments}
                                    onChange={evt => setComments(evt.target.value)}
                                    type="textarea"
                                    placeholder={translate("COMMENT")}
                                    aria-label={translate("COMMENT")}
                                />
                                <p />
                                <br />
                                <Button onClick={handleSubmit} className="btn btn-primary ml-auto col-auto" type="submit">
                                    {translate("REQUEST_CALLBACK")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {submitted && <h4>Your callback request has been submitted. You may close this window.</h4>}
        </div>
    );
};

export default CallbackForm;
import React from 'react';
import ReactDOM from 'react-dom/client';
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { BrowserRouter as Router } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { b2cPolicies, loginRequest, msalConfig } from './authConfig';
import { MsalAuthService } from "./authService.js";
import App from './App';
import Cookies from "js-cookie";

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';

export const CallCenterInstanceName = "cogswell-call-center";

// Look for optional "lang" query parameter and use it if provided
const langParam = new URLSearchParams(window.location.search).get("lang");
if (langParam) {
    // Store preferred language in a cookie so that it's persistent across requests to this app
    Cookies.set("lang", langParam);
}

const Language = Cookies.get("lang") || "en";
const Translations = {
    en: {
        REQUEST_SUBMITTED_PLEASE_WAIT:
            "Request submitted. Please wait for the meeting to start...",
        SUPPORT_REQUEST_CANCELED_YOU_MAY_CLOSE:
            "Support request has been canceled. You may close this window.",
        SUPPORT_REQUEST_FORM: "Please submit details and wait for a Support representative to contact you",
        REQUEST_SUPPORT: "Request Support",
        SUBMITTING_YOUR_REQUEST: "Submitting your request for support...",
        WAIT_FOR_MEETING_TO_START: "Wait for the meeting to start...",
        CANCEL_REQUEST: "Cancel",
        REQUEST_CALLBACK: "Request Callback",
        URGENT: "Urgent",
        NOT_URGENT: "Not Urgent",
        PLEASE_ASK_YOUR_ADMINISTRATOR:
            "Please ask your administrator for instructions.",
        ACCESS_RESTRICTED: "Access Restricted",
        CUSTOMER_PORTAL: "Customer Portal",
        CALL_CENTER_LOBBY: "Call Center Lobby",
        COMMENT: "Comment",
        LOADING: "Loading",
        CALL_CENTER_IS_BUSY: "The Call Center is experiencing longer than expected wait times. Please continue waiting or consider requesting a callback."
    }
};
export const translate = (key) => {
    const table = Translations[Language];
    if (!table) {
        return key;
    }
    return table[key] || key;
};

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);
export const authService = new MsalAuthService(msalInstance, {
    signInAuthority: b2cPolicies.authorities.signIn.authority,
    signInScopes: loginRequest.scopes
});

let currentPage = {};
export const setCurrentPage = (page) => (currentPage = page);
export const getCurrentPage = () => currentPage;

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

const manifest = {
    name: document.title,
    short_name: document.title,
    description: document.title,
    start_url: window.location.href
  };
const stringManifest = JSON.stringify(manifest);
const blob = new Blob([stringManifest], {type: 'application/json'});
const manifestURL = URL.createObjectURL(blob);
document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router>
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
                <App authService={authService} />
            </MsalAuthenticationTemplate>
        </MsalProvider>
    </Router>
);

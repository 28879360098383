import React, { useEffect, useState } from "react";
import { Form } from 'react-bootstrap';
import { useSearchParams } from "react-router-dom";
import { Button } from 'react-bootstrap';
import Select from "react-select";
import { CallCenter } from "../onsight-plus-ext";
import { authService, CallCenterInstanceName, getCurrentPage, translate } from "../index";

export const RequestForm = () => {
    const locationOptions = [
        { value: "US-NC", label: "Raleigh, NC" },
        { value: "US-FL", label: "Tampa, FL" },
        { value: "US-MI", label: "Detroit, MI" },
        { value: "UK-LON", label: "London, UK" },
    ];
    const equipmentOptions = [
        { value: "a1000", label: "Sensor A-1000" },
        { value: "b2000", label: "Sensor B-2000" },
        { value: "c3000", label: "Sensor C-3000" },
        { value: "", label: "None" }
    ];
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [messageVisibility, setMessageVisibility] = useState("hidden");
    const [requesterUser, setRequesterUser] = useState("");
    const [requesterEmail, setRequesterEmail] = useState("");
    const [selectedLocation, setSelectedLocation] = useState(locationOptions[0]);
    const [selectedEquipment, setSelectedEquipment] = useState(equipmentOptions[0]);
    const [comments, setComments] = useState("");
    const location = window.location;
    const [searchParams, setSearchParams] = useSearchParams();

    const handleSubmit = async () => {
        setButtonDisabled(true);
        setMessageVisibility("unset");
        
        const pathParts = getCurrentPage().name.split("/");
        const requesterCompany = pathParts.length > 0 ? pathParts[0] : "";

        const newWindow = window.open("about:blank", "_blank");
        const response = await CallCenter.makeRequest(CallCenterInstanceName, {
            requesterCompany: "",
            requesterUser,
            requesterEmail,
            subject: "",
            requesterComments: comments,
            requesterInputs: {
                "location": selectedLocation?.value ?? "",
                "equipment": selectedEquipment?.value ?? ""
            }
        });
        if (response.status === 201 && response.data.id) {
            let pathname = location.pathname;
            if (!pathname.endsWith('/')) {
                pathname += '/';
            }
            newWindow.location.href = `${pathname}lobby?ccReqId=${response.data.id}&email=${requesterEmail}`;
            setComments("");
            setButtonDisabled(false);
            setMessageVisibility("hidden");
        }
    };

    const loadUserDetails = async () => {
        const paramName = searchParams.get("name");
        const paramEmail = searchParams.get("email");
        const currentUser = await authService.getUserAccount();

        setRequesterUser(paramName);
        setRequesterEmail(paramEmail);

        if (!paramName && currentUser.name)  {
            // No query string param; try using authenticated user name, if available
            setRequesterUser(currentUser.name);
        }
        if (!paramEmail && currentUser.email) {
            // No query string param; try using authenticated user email, if available
            setRequesterEmail(currentUser.email );
        }
    };

    useEffect(() => {
        loadUserDetails();
    }, []);

    return (
        <>
            <div style={{margin: "20px 0px 40px 20px"}}>
                <img src="/images/cogswell-logo.png" title="Logo" style={{height: "60px"}}></img>
                <span style={{fontSize: "x-large", fontWeight: "bold", marginLeft: "50px"}}>Request Support</span>
            </div>

            <div className="card" style={{margin: "0.5em"}}>
                <div className="card-header font-weight-bold">{translate("SUPPORT_REQUEST_FORM")}</div>
                <div className="form-body">
                    <div className="f-rows">
                        <div className="f-cols">
                            <div className="f-col">
                                <div className="f-rows">
                                    <div className="f-row">
                                        <Form.Control name="requesterUser"
                                            placeholder="Your Name"
                                            value={requesterUser}
                                            onChange={evt => setRequesterUser(evt.target.value)}
                                            aria-label="Your Name"
                                            aria-describedby="basic-addon3" />
                                    </div>
                                    <div className="f-row">
                                    <Form.Control name="requesterEmail"
                                            placeholder="Your Email"
                                            value={requesterEmail}
                                            onChange={evt => setRequesterEmail(evt.target.value)}
                                            aria-label="Your Email"
                                            aria-describedby="basic-addon3" />
                                    </div>
                                    <div className="f-row">
                                    <Select name="location"
                                            placeholder="Select Location"
                                            className="w-100"
                                            options={locationOptions}
                                            value={selectedLocation}
                                            onChange={selection => setSelectedLocation(selection)} />
                                    </div>
                                    <div className="f-row">
                                        <Select name="equipment"
                                            placeholder="Select Equipment"
                                            className="w-100"
                                            options={equipmentOptions}
                                            value={selectedEquipment}
                                            onChange={selection => setSelectedEquipment(selection)} />
                                    </div>
                                </div>
                            </div>
                            <div className="f-col">
                                <textarea className="w-100" style={{minHeight: "10.85em"}} onChange={evt => setComments(evt.target.value)} />
                            </div>
                        </div>
                        <div style={{textAlign: "center", paddingTop: "0.5em"}}>
                            <Button onClick={handleSubmit} disabled={buttonDisabled} className="btn btn-primary ml-auto col-auto" type="submit">
                                {translate("REQUEST_SUPPORT")}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <p />
            <span style={{ visibility: messageVisibility }}>{translate("SUBMITTING_YOUR_REQUEST")}</span>
        </>
    );
};
import React, { useState, useEffect, useRef } from "react";
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { RestrictedPage } from "./pages/RestrictedPage";
import { LoadingPage } from "./pages/LoadingPage";
import { UserWebApps } from "./onsight-plus-ext";
import { Container } from "./components/dashboard/Container";
import { NotFound } from "./components/NotFound";
import { setCurrentPage } from "./index";
import CallCenterLobby from "./components/CallCenterLobby";
import UrlHelper from "./utils/UrlHelper";
import { RequestForm } from "./components/RequestForm";
import { matchRoutes, useLocation } from "react-router-dom";

import "./styles/index.css";
import "./styles/App.css";

import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";


const App = ({ authService }) => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [appPages, setAppPages] = useState([]);
    const [pageId, setPageId] = useState("");
    const pageIdRef = useRef("")
    pageIdRef.current = pageId
    const [searchParams, _] = useSearchParams();
    const [userRoutes, setUserRoutes] = useState([]);

    const [isAdmin, setIsAdmin] = useState(false);
    const isAdminRef = useRef(false);
    isAdminRef.current = isAdmin;

    const [isProd, setIsProd] = useState(true);
    const isProdRef = useRef(true);
    isProdRef.current = isProd;

    // let page = useCurrentPage(appPages);
    // useEffect(() => {
    //     setCurrentPage(page);
    // }, [page]);

    const location = useLocation();

    const onAuthorize = async (userAccount) => {
        setCurrentUser(userAccount);
        let pageIdParam = searchParams.get("pageId")
        pageIdRef.current = pageIdParam;
        setPageId(pageIdParam);

        try {
            userAccount.roles.forEach((r) => {
                if (r.includes("Administrator")) {
                    isAdminRef.current = true;
                    setIsAdmin(true);
                }
            });

            // still do not display hamburger menu even if Admin is not using admin path
            if (isAdminRef.current) {
                const isAdminPath = window.location.pathname;
                if (isAdminPath !== "/admin") {
                    isAdminRef.current = false;
                    setIsAdmin(false);
                }

                const url = window.location;
                if (url.hostname === "localhost") {
                    isProdRef.current = false;
                    setIsProd(false);
                }
            }

            setIsAuthorized(true);

            const userPages = await UserWebApps.getUserPages();
            setAppPages(userPages);

            const userRoutes = userPages.map((page) =>
            {
                let path = UrlHelper.extractRoute(page.path);
                if (page.query) {
                    path += `?${page.query}`;
                }

                return (
                    <Route {...page}
                        key={page.id}
                        element={<RequestForm userAccount={currentUser} />}
                    />
                );
            });

            const lobbyRoutes = userRoutes.map((route) => route.props.index
                ? <Route path={`lobby`} key={`lobby`} element={<CallCenterLobby />} />
                : <Route path={`${route.props.path}/lobby`} key={`${route.key}/lobby`} element={<CallCenterLobby />} />);

            userRoutes.push(lobbyRoutes);
            // Add some standard routes in addition to user-specific ones
            userRoutes.push(<Route key="_404" path="*" element={<NotFound status={404} />} />);
            userRoutes.push(<Route key="_admin" path="/admin"
                element={isAdminRef.current &&
                    <Container
                        pageId={pageIdRef.current}
                        userAccount={currentUser}
                        isAdmin={true}
                        isProd={isProdRef.current}
                    />}
            />);

            setUserRoutes(userRoutes);
        } catch (error) {
            setNotAuthorized(true);
        }
    };

    useEffect(() => {
        authService.addAuthorizationHandler(onAuthorize);
    }, []);

    useEffect(() => {
        const matches = matchRoutes(appPages, location);
        if (matches?.length > 0) {
            setCurrentPage(matches[0].route);
        }
    }, [appPages]);

    return (
        <>
            {isAuthorized && (
                <>
                <Routes>
                    {userRoutes}
                </Routes>
                </>
            )}
            {notAuthorized && <RestrictedPage />}
            {!isAuthorized && !notAuthorized && <LoadingPage />}
        </>
    );
};
export default App;
